@import "styles/variables";

.padding {
  padding-top: 104px;

  @media screen and (max-width: $breakpoint-lg) {
    padding-top: 60px;
  }
}

.loadingWrapper {
  position: relative;
  min-height: 500px;

  @media screen and (max-width: $breakpoint-lg) {
    min-height: 300px;
  }
}
